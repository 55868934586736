import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/index';
import { Constants } from 'app/shared/config/constants';
import { map } from 'rxjs/operators';
import { of } from "rxjs";

interface HealthStatus {
  buildInfo: any;
  healthy: boolean;
  name: string;
}

const HEALTHY = 'I am healthy!';

@Injectable ( {
  providedIn: 'root'
} )
export class HealthService {

  private readonly url = Constants.healthServiceUrl;

  constructor( private http: HttpClient ) {
  }

  getApiStatus(): Observable<HealthStatus> {
    return of ( {
      buildInfo: {
        version: '1.0.0',
        commit: '123456',
        buildTime: '2020-01-01T00:00:00Z'
      },
      healthy: true,
      name: HEALTHY
    } );
    // return this.http.get ( this.url ) as Observable<HealthStatus>;
  }

  isBackendOnline(): Observable<boolean> {
    return this.getApiStatus ()
      .pipe ( map ( ( t => t.healthy ) ) );
  }
}
