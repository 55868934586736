import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuditHistory } from 'app/shared/models';

@Injectable ( {
  providedIn: 'root'
} )
export class AuditHistoryService {

  constructor( private http: HttpClient ) {
  }

  get( url: string, pageNumber = 1, pageSize = 50 ): Observable<AuditHistory> {
    const params = new HttpParams ().appendAll ( { pageNumber, pageSize } );
    return this.http.get<AuditHistory> ( url, { params } );
  }
}
