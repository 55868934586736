import { Injectable } from '@angular/core';
import { Constants } from 'app/shared/config/constants';
import { HttpClient } from '@angular/common/http';
import { LoginType, UiConfig } from 'app/shared/models';

@Injectable ( {
  providedIn: 'root'
} )
export class UiConfigService {

  private readonly url = Constants.uiConfigServiceUrl;
  private config: UiConfig;

  constructor ( private http: HttpClient ) {
  }

  get uiConfig (): never | UiConfig {
    if ( !this.config ) {
      return localStorage.getItem ( Constants.localStorageKeys.uiConfig ) ?
        JSON.parse ( atob ( localStorage.getItem ( Constants.localStorageKeys.uiConfig ) ) ) : {};
    }
    return this.config;
  }

  set uiConfig ( config: UiConfig | any ) {
    if ( !config?.id ) {
      this.clear ();
    } else {
      this.config = { ...config };
      this.config.ssoButtonText = this.config.ssoButtonText || Constants.uiConfigDefault.ssoButtonText;
      this.config.landingPageHeaderHtmlSnippet =
        this.config.landingPageHeaderHtmlSnippet || Constants.uiConfigDefault.landingPageHeaderHtmlSnippet;
      this.config.landingPageFooterHtmlSnippet =
        this.config.landingPageFooterHtmlSnippet || Constants.uiConfigDefault.landingPageFooterHtmlSnippet;
      localStorage.setItem ( Constants.localStorageKeys.uiConfig, btoa ( JSON.stringify ( this.config ) ) );
    }
  }

  byKey ( key: string ): any {
    return this.uiConfig[ key ] || null;
  }

  get isCustom (): boolean {
    return !!this.uiConfig?.id;
  }

  get landingPageFooterHtmlSnippet (): string {
    return this.byKey ( 'landingPageFooterHtmlSnippet' ) || Constants.uiConfigDefault.landingPageFooterHtmlSnippet;
  }

  get landingPageHeaderHtmlSnippet (): string {
    return this.byKey ( 'landingPageHeaderHtmlSnippet' ) || Constants.uiConfigDefault.landingPageHeaderHtmlSnippet;
  }

  get urlSegmentKey (): string | null {
    return this.byKey ( 'urlSegmentKey' );
  }

  get name (): string {
    return this.byKey ( 'name' );
  }

  get ssoButtonText (): string {
    return this.byKey ( 'ssoButtonText' ) || Constants.uiConfigDefault.ssoButtonText;
  }

  get ssoTid (): string {
    return this.byKey ( 'ssoTid' );
  }

  get whiteLabelLogoUri (): string {
    return this.byKey ( 'whiteLabelLogoUri' ) || Constants.uiConfigDefault.whiteLabelLogoUri;
  }

  get homePageHtmlSnippet (): string | null {
    return this.byKey ( 'homePageHtmlSnippet' ) || null;
  }

  get incidentFinishPageHtmlSnippet (): string | null {
    return this.byKey ( 'incidentFinishPageHtmlSnippet' ) || null;
  }

  get customCss (): string | null {
    return this.byKey ( 'customCss' ) || null;
  }

  get showStartIncidentForm (): boolean {
    return this.byKey ( 'showStartIncidentForm' ) || false;
  }

  get dateOfLossLabel (): string {
    return this.byKey ( 'dateOfLossLabel' ) || 'Date of Loss';
  }

  get showDateOfLoss (): boolean {
    return this.byKey ( 'showDateOfLoss' ) || false;
  }

  get lobDefaultValue (): string {
    return this.byKey ( 'lobDefaultValue' ) || '';
  }

  get startIncidentButtonText (): string {
    return this.byKey ( 'startIncidentButtonText' ) || 'Start Incident';
  }

  get reportingLocationDefaultValue (): string {
    return this.byKey ( 'reportingLocationDefaultValue' ) || '';
  }

  get benefitStateDefaultValue (): string {
    return this.byKey ( 'benefitStateDefaultValue' ) || '';
  }

  get questionnaireDefaultValue (): string {
    return this.byKey ( 'questionnaireDefaultValue' ) || '';
  }

  get insuredDefaultValue (): string {
    return this.byKey ( 'insuredDefaultValue' ) || '';
  }

  get employerDefaultValue (): string {
    return this.byKey ( 'employerDefaultValue' ) || '';
  }

  get showEmployeeLookup (): boolean {
    return this.byKey ( 'showEmployeeLookup' ) || false;
  }

  get employeeIdLabel (): string {
    return this.byKey ( 'employeeLookupLabel' ) || 'Employee';
  }

  get employeeLookupTooltip (): string {
    return this.byKey ( 'employeeLookupTooltip' ) || '';
  }

  get reportingLocationLabel (): string {
    return this.byKey ( 'reportingLocationLabel' ) || Constants.reportingLocationPrompt;
  }

  get useEmployeeLookupAsReportingLocation (): boolean {
    return this.byKey ( 'useEmployeeLookupAsReportingLocation' ) || false;
  }

  get showWelcomePage (): boolean {
    return this.byKey ( 'showWelcomePage' );
  }

  get loginType (): LoginType {
    return this.byKey ( 'loginType' ) as LoginType;
  }

  clear () {
    this.config = undefined;
    localStorage.removeItem ( Constants.localStorageKeys.uiConfig );
  }
}
