import { Injectable } from '@angular/core';
import { Incident } from '../models';
import { SearchCache } from '../models/search-cache.model';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private searchCache: SearchCache = undefined;

  constructor() {
  }

  setUserSearchCriteria(args: SearchCache, update = false): void {
    if (update) {
      const updatedPage: SearchCache = {
        ...this.searchCache,
        page: args.page
      };

      this.searchCache = updatedPage;
    } else {
      this.searchCache = args;
    }
  }

  saveSearchRecord(selectedRecord: Incident): void {
    this.searchCache = {
      ...this.searchCache,
      selectedRecord
    };
  }

  clearSearchCache(): void {
    this.searchCache = undefined;
  }

  get currentSearchCache(): SearchCache {
    return this.searchCache;
  }
}
