import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Attachment } from 'app/shared/models';
import { Observable } from 'rxjs';
import { AuthService } from 'app/shared/services/auth.service';
import { Constants } from 'app/shared/config/constants';
import { map } from "rxjs/operators";

@Injectable ( {
  providedIn: 'root'
} )
export class AttachmentService {

  readonly attachmentsUrl = Constants.attachmentsServiceUrl;

  constructor( private httpClient: HttpClient, private authService: AuthService ) {
  }

  public uploadAttachment( attachment: Attachment, incidentId: string ) {
    const headers = new HttpHeaders ( {
      'Content-Type': 'application/octet-stream'
    } );

    let params = new HttpParams ()
      .append ( 'filename', `${ attachment.name }` );
    if ( attachment.description ) {
      params = params.append ( 'description', `${ attachment.description }` );
    }
    const url = this.attachmentsUrl.replace ( '{incidentId}', incidentId );
    return this.httpClient.post ( url, attachment.file, {
      headers,
      params
    } );
  }

  public getAttachments( incidentId: string ): Observable<any[]> {
    const url = this.attachmentsUrl.replace ( '{incidentId}', incidentId );
    return this.httpClient.get<any[]> ( url );
  }

  public downloadAttachment( incidentId: string, attachmentId: string, fileName: string ) {
    const url = this.attachmentsUrl.replace ( '{incidentId}', incidentId ) + '/' + attachmentId + '/file';
    return this.httpClient.request ( 'GET', url, {
      observe: 'response',
      responseType: 'blob',
    } );
  }

  public deleteAttachment( incidentId: string, attachmentId: string ) {
    const url = this.attachmentsUrl.replace ( '{incidentId}', incidentId ) + '/' + attachmentId;
    return this.httpClient.delete ( url );
  }

  updateAttachment( incidentId: string, attachment: any ) {
    const url = this.attachmentsUrl.replace ( '{incidentId}', incidentId ) + '/' + attachment.id;
    return this.httpClient.put ( url, attachment );
  }

  getAuditHistory( incidentId: string, pageNumber = 1, pageSize = 50 ): Observable<any> {
    const url = this.attachmentsUrl.replace ( '{incidentId}', incidentId ) + '/audit-history';
    const params = new HttpParams ().appendAll ( { pageNumber, pageSize } );
    return this.httpClient.get<any> ( url, { params } ).pipe ( map ( history => {
      return history;
    } ) );
  }
}
