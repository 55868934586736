import { Injectable } from '@angular/core';
import {
  BusinessUnit,
  BusinessUnitTypeahead
} from '../models/business-unit.model';

@Injectable ( {
  providedIn: 'root'
} )
export class HelperService {

  readonly controlDelimiter = '___';

  constructor() {
  }

  fieldName( key: string, sequence = 0 ) {
    return `${ key }${ this.controlDelimiter }${ sequence }`;
  }

  transformToBuSearchTypeahead( model: BusinessUnit | BusinessUnit[] ): BusinessUnitTypeahead | BusinessUnitTypeahead[] | any {
    if ( model && Array.isArray ( model ) ) {
      const transformed = model.map ( e => {
        return this.businessUnitTypeaheadTransformed ( e );
      } );
      return transformed;
    }
    if ( typeof model === 'undefined' ) {
      return undefined;
    } else if ( !Array.isArray ( model ) && model ) {
      return this.businessUnitTypeaheadTransformed ( model );
    }
  }

  private businessUnitTypeaheadTransformed = ( model: BusinessUnit ): BusinessUnitTypeahead => {
    return {
      id: model.id,
      displayName: model && model.displayName ? model.displayName : model.name
    };
  }
}
