import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Buffer} from 'buffer/';
import {ungzip} from 'pako';
import { Constants } from "app/shared/config/constants";

@Injectable ( {
  providedIn: 'root'
} )
export class TokenService {

  private tokenString: string;
  private tokenObject: any;

  constructor( private jwtHelper: JwtHelperService ) {
  }

  set token( token: string ) {
    localStorage.setItem ( Constants.localStorageKeys.reporterAccessToken, token );
    this.tokenString = token;
    this.tokenObject = this.decodedToken;
  }

  get token(): any {
    if ( !this.tokenString ) {
      this.tokenString = localStorage.getItem ( Constants.localStorageKeys.reporterAccessToken );
    }
    return this.tokenString ? this.decodedToken : null;
  }

  delete() {
    localStorage.removeItem ( Constants.localStorageKeys.reporterAccessToken );
    this.tokenObject = undefined;
    this.tokenString = undefined;
  }

  private get decodedToken(): any {
    if ( this.tokenObject ) {
      return this.tokenObject;
    }
    const tokenParts = this.tokenString?.split( '.' );
    if ( tokenParts.length !== 3 ) {
      return null;
    }
    const header = atob( tokenParts[ 0 ] );
    const headerObject = JSON.parse( header );
    if ( headerObject?.zip === 'GZIP' ) {
      const gzippedPayload = Buffer.from( tokenParts[ 1 ], 'base64' );
      const payload = new TextDecoder().decode( ungzip( gzippedPayload ) );
      this.tokenObject = JSON.parse ( payload );
    } else {
      this.tokenObject = this.jwtHelper.decodeToken ( this.tokenString );
    }
    return this.tokenObject;
  }

  get reporterUserWorkflow() {
    return this.tokenObject?.workflow;
  }

  get reporterUserPermissions() {
    return this.tokenObject?.permissions || [];
  }
}
