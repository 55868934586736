import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable ( {
  providedIn: 'root'
} )
export class FieldValidationErrorService {

  constructor() {
  }

  private errorSubject = new BehaviorSubject<Record<string, unknown> | null> ( null );
  public errorMessage$ = this.errorSubject.asObservable ();

  broadcastError( message: Record<string, unknown> ): void {
    this.errorSubject.next ( message );
  }

  clearError(): void {
    this.errorSubject.next ( null );
  }
}
