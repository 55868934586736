import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Enum } from 'app/shared/models/enum.model';
import { Constants } from 'app/shared/config/constants';

@Injectable ( {
  providedIn: 'root'
} )
export class JurisdictionService {

  readonly url = Constants.jurisdictionsServiceUrl;

  constructor( private http: HttpClient ) {
  }

  allByCountry( countryCd = '' ): Observable<Enum[]> {
    const params = new HttpParams ().append ( 'countryCd', countryCd );
    return this.http.get<Enum[]> ( `${ this.url }`, countryCd ? { params } : {} );
  }
}
