import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from 'app/shared/config/constants';
import { Observable } from 'rxjs';
import { Employee } from 'app/shared/models';

@Injectable ( {
  providedIn: 'root'
} )
export class EmployeeService {

  readonly url = Constants.employeesUrl;

  constructor( private http: HttpClient ) {
  }

  searchByLookupId( activeDate: string, locationId: string, employeeLookupId: string ): Observable<Employee> {
    const params = new HttpParams ()
      .append ( 'activeDate', activeDate )
      .append ( 'locationId', locationId )
      .append ( 'employeeLookupId', employeeLookupId );
    return this.http.get<Employee> ( this.url, { params } );
  }
}
